import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, slug, image }) {
  const { site } = useStaticQuery(
    graphql`
      query MetaDataQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image || "https://usehyperlink.com/images/preview.png",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image || "https://usehyperlink.com/images/preview.png",
        }
      ].concat(meta)}
    >
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800|Lora:400,700&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <link rel="canonical" href={`https://usehyperlink.com${slug || ''}`} />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-GBK75R610M"></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
    
        gtag('config', 'G-GBK75R610M');
        gtag('config', 'UA-148098746-1');
        `}
      </script>
      <script async src="https://usehyperlink.com/hyper.js"></script>
      <script>{`
        window.hyperConfig = window.hyperConfig || [];
        function setupHyper() { hyperConfig.push(arguments); }
        setupHyper('js', new Date());
        setupHyper('config', '88xxx8');
        `}
      </script>

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default SEO
