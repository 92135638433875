import React from "react"
import { Link } from "gatsby"

class Layout extends React.Component {
  state = {
    menuExpanded: false
  };

  toggleState() {
    this.setState({ menuExpanded: !this.state.menuExpanded });
  }

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>
        <header>
          <div className="logo">
            <a className="hyperlink" href="https://usehyperlink.com/">Hyperlink</a>
            {location.pathname && location.pathname.startsWith("/blog") && <span> | <a className="blog" href="https://usehyperlink.com/blog/">Blog</a></span>}
            {location.pathname && location.pathname.startsWith("/pricing") && <span> | <a className="blog" href="https://usehyperlink.com/pricing/">Pricing</a></span>}
            {location.pathname && location.pathname.startsWith("/faq") && <span> | <a className="blog" href="https://usehyperlink.com/faq/">FAQ</a></span>}
            {location.pathname && location.pathname.startsWith("/features") && <span> | <a className="blog" href="https://usehyperlink.com/features/">Features</a></span>}
            {location.pathname && location.pathname.startsWith("/documentation") && <span> | <a className="blog" href="https://usehyperlink.com/documentation/">API</a></span>}
          </div>
          <div onClick={this.toggleState.bind(this)} className="menu-button material-icons">menu</div>
          <div className={"menu " + (this.state.menuExpanded ? "expanded" : "")}>
            <a href="/features/">Features</a>
            <a href="/pricing/">Pricing</a>
            <a href="/faq/">FAQ</a>
            <a href="/app/">Login</a>
            <a className="primary" href="/pricing/">Get started</a>
          </div>
        </header>
        <main>
          {children}
        </main>
        <footer>
          <div className="footer-sections">
            <div className="footer-section">
              <h3>Get the app</h3>
              <div className="download-buttons">
                <a href="https://apps.apple.com/us/app/hyperlink-custom-push-links/id1480418373?ls=1"><img className="download-button" src="https://usehyperlink.com/images/download.svg" /></a>
                <a href="https://play.google.com/store/apps/details?id=app.siggi.android.link"><img className="download-button" src="https://usehyperlink.com/images/download_play.png" /></a>
              </div>
            </div>
            <div className="footer-section">
              <h3>Product</h3>
              <a href="https://usehyperlink.com/pricing/">Pricing</a>
              <a href="https://usehyperlink.com/subscription/">Get Custom Domain</a>
              <a href="https://usehyperlink.com/documentation/">API Docs</a>
              <a href="https://usehyperlink.com/features/">Features</a>
              <a href="https://usehyperlink.com/app/">Sign up</a>
              <a href="https://usehyperlink.com/app/">Login</a>
            </div>

            <div className="footer-section">
              <h3>Platforms</h3>
              <a href="https://apps.apple.com/us/app/hyperlink-custom-push-links/id1480418373?ls=1">iOS App</a>
              <a href="https://play.google.com/store/apps/details?id=app.siggi.android.link">Android App</a>
              <a href="https://chrome.google.com/webstore/detail/hyperlink/dlgncnofjdagniahkcimljhineapppbi">Chrome Extension</a>
              <a href="https://usehyperlink.com/app/">Web App</a>
              <a target="_blank" href="https://zapier.com/developer/public-invite/66721/a7078fc6d28dc7d1d70494c08f845da5/">Zapier Integration</a>
            </div>

            <div className="footer-section">
              <h3>Company</h3>
              <a href="https://usehyperlink.com/blog/">Blog</a>
              <a href="mailto:contact@usehyperlink.com">Contact</a>
              <a href="https://usehyperlink.com/privacy/">Privacy Policy</a>
              <a href="https://usehyperlink.com/terms/">Terms of Service</a>
              <a href="mailto:affiliate@usehyperlink.com">Become an Affiliate</a>
            </div>
            <div className="footer-section">
              <h3>Connect</h3>
              <a target="_blank" href="https://twitter.com/hyperlink_app">Twitter</a>
              <a target="_blank" href="https://www.instagram.com/hyperlink_app">Instagram</a>
              <a target="_blank" href="https://www.facebook.com/hyperlinkapp">Facebook</a>
            </div>
          </div>
          <div className="footer-copyright">
            © {new Date().getFullYear()} Iteration, Inc.
          </div>
        </footer>
      </div >
    )
  }
}

export default Layout
